/* Base */
/* ==========================================================================
 Mixins
 ========================================================================== */
/*--------------------------------
Z-Index Manager
Usage:
.site-header {
    z-index: z('site-header');
}
*/
/*--------------------------------
When using ::before and ::after you'll always need these three.
So we're saving two lines of code every time you use this.
Usage:
div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*--------------------------------
Creating triangles
*/
/*--------------------------------
Calculated REM sizes with PX fallback
Usage:
p {
  @include font-size(14px)
}
Output:
p {
  font-size: 14px; //Will be overridden if browser supports rem
  font-size: 0.8rem;
}
*/
/*--------------------------------
Cross-Browser opacity
Usage:
.faded-text {
  @include opacity(0.8);
}
*/
/*--------------------------------
Retina images
Usage:
.element {
  @include retina {
    background-image: url(../img/background@2x.png);
  }
}
*/
/*--------------------------------
Responsive ratio
Used for creating scalable elements that maintain the same ratio
Usage:
.element {
  @include responsive-ratio(400, 300);
}
*/
/*--------------------------------
Truncate copy to width
*/
/*--------------------------------
Fluid Property
http://www.adrenalinmedia.com.au/the-agency/insights/this-changes-everything-css-fluid-properties.aspx
HTML:
<h1 class="element">
  <span>Text to replace</span>
</h1>
example:
h1 {
  @include fp(font-size, 50, 100); // 50px at 320, 100px at 1280;
}
output:
h1 {
  font-size: calc(3.125vw + 40px); //This is the magic!
}
@media (max-width:320px){ //Clips the start to the min value
  font-size:50px;
}
@media (min-width:1920px){ //Clips the end to the max value
  font-size:100px;
}
*/
/* ==========================================================================
 Presets
 ========================================================================== */
/* usage
.my-box {
  padding: 10px;

  @include for-size(above-320) {
    padding: 20px;
  }
}
*/
/* ==========================================================================
 Utilities
 ========================================================================== */
.ie img {
  -ms-interpolation-mode: bicubic; }

.accelerated {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0); }

.backface {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -webkit-transform-style: flat;
  -moz-transform-style: flat;
  -ms-transform-style: flat;
  -o-transform-style: flat;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0); }

.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  text-indent: -9999px; }

.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix {
  *zoom: 1; }

.font-smoothing, body, h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.hidden {
  display: block; }

.real-hidden {
  display: none; }

.u-full-width {
  width: 100%;
  box-sizing: border-box; }

.u-max-full-width {
  max-width: 100%;
  box-sizing: border-box; }

.u-pull-right {
  float: right; }

.u-pull-left {
  float: left; }

.no-scroll {
  overflow: hidden !important; }

.force-reveal .reveal {
  visibility: visible !important;
  -webkit-transform: translateY(0) scale(1) !important;
  opacity: 1 !important;
  transform: translateY(0) scale(1) !important;
  opacity: 1 !important;
  -webkit-transition: -webkit-transform 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s, opacity 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s !important;
  transition: transform 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s, opacity 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s !important; }

.flash {
  animation: flash 1s infinite; }

@keyframes flash {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.reduced-flash {
  animation: reduced-flash 1s infinite; }

@keyframes reduced-flash {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.5; } }

.obj-cover, .fullbleedGallery .fullbleedGallery-content .gallerySlide.fullBleed img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'; }

.obj-contain, .workGalleryHolder .workGallery .workGallerySlide img, .page-workList .workPreviewHolder .workPreview {
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: 'object-fit: contain;'; }

.rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.rotate-270, .workGalleryHolder .workGallery .workGallerySlide .photoCredit {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg); }

.flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1); }

.flip-horizontal.flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1); }

/* ==============
Clearing
 ============== */
.container:after,
.row:after,
.u-cf {
  content: "";
  display: table;
  clear: both; }

/* Typography */
/* ==========================================================================
 Typography
 ========================================================================== */
/* Fonts */
@font-face {
  font-family: 'FndrsGrtskL';
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
  src: url("https://yabupushelberg.com/fonts/foundersgrotesk/FoundersGroteskWeb-Light.eot");
  src: url("https://yabupushelberg.com/fonts/foundersgrotesk/FoundersGroteskWeb-Light.eot?#iefix") format("embedded-opentype"), url("https://yabupushelberg.com/fonts/foundersgrotesk/FoundersGroteskWeb-Light.woff2") format("woff2"), url("https://yabupushelberg.com/fonts/foundersgrotesk/FoundersGroteskWeb-Light.woff") format("woff"); }

@font-face {
  font-family: 'FndrsGrtsk';
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
  src: url("https://yabupushelberg.com/fonts/foundersgrotesk/FoundersGroteskWeb-Regular.eot");
  src: url("https://yabupushelberg.com/fonts/foundersgrotesk/FoundersGroteskWeb-Regular.eot?#iefix") format("embedded-opentype"), url("https://yabupushelberg.com/fonts/foundersgrotesk/FoundersGroteskWeb-Regular.woff2") format("woff2"), url("https://yabupushelberg.com/fonts/foundersgrotesk/FoundersGroteskWeb-Regular.woff") format("woff"); }

@font-face {
  font-family: 'FndrsGrtsk';
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
  src: url("https://yabupushelberg.com/fonts/foundersgrotesk/FoundersGroteskWeb-RegularItalic.eot");
  src: url("https://yabupushelberg.com/fonts/foundersgrotesk/FoundersGroteskWeb-RegularItalic.eot?#iefix") format("embedded-opentype"), url("https://yabupushelberg.com/fonts/foundersgrotesk/FoundersGroteskWeb-RegularItalic.woff2") format("woff2"), url("https://yabupushelberg.com/fonts/foundersgrotesk/FoundersGroteskWeb-RegularItalic.woff") format("woff"); }

@font-face {
  font-family: 'OstAnt';
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
  src: url("https://yabupushelberg.com/fonts/ostiaantica/Ostia_Antica_WEB-Regular.eot");
  src: url("https://yabupushelberg.com/fonts/ostiaantica/Ostia_Antica_WEB-Regular.eot?#iefix") format("embedded-opentype"), url("https://yabupushelberg.com/fonts/ostiaantica/Ostia_Antica_WEB-Regular.woff2") format("woff2"), url("https://yabupushelberg.com/fonts/ostiaantica/Ostia_Antica_WEB-Regular.woff") format("woff"); }

@font-face {
  font-family: 'OstAnt';
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
  src: url("https://yabupushelberg.com/fonts/ostiaantica/Ostia_Antica_WEB-Italic.eot");
  src: url("https://yabupushelberg.com/fonts/ostiaantica/Ostia_Antica_WEB-Italic.eot?#iefix") format("embedded-opentype"), url("https://yabupushelberg.com/fonts/ostiaantica/Ostia_Antica_WEB-Italic.woff2") format("woff2"), url("https://yabupushelberg.com/fonts/ostiaantica/Ostia_Antica_WEB-Italic.woff") format("woff"); }

.body-font, body,
.body-reg {
  font-family: 'FndrsGrtsk', "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; }

.header-font, .header-1, h1, .header-2, h2 {
  font-family: 'FndrsGrtsk', "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; }

.light-font, .workgrid-letter, .page-work .workGrid .workGridBlock .workGridBlock-item.letterBlock {
  font-family: 'FndrsGrtskL', "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; }

.alt-font, .page-workList .workListHolder .workList {
  font-family: 'OstAnt', "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; }

.fab {
  font-family: 'FontAwesome5Brands'; }

/* Typographic Rules */
body,
.body-reg {
  font-weight: normal; }

.header-1, h1 {
  letter-spacing: 0em; }

.header-2, h2 {
  letter-spacing: 0em; }

.header-3, h3, h4, h5, h6 {
  letter-spacing: 0em; }

footer {
  text-align: left; }

p {
  margin-top: 0; }

.uppercase {
  text-transform: uppercase; }

/* NOTE
html is set to 62.5% so that all the REM measurements throughout Skeleton
are based on 10px sizing. So basically 1.5rem = 15px :) */
html {
  font-size: 62.5%; }

body {
  font-size: 1.5em;
  /* currently ems cause chrome bug misinterpreting rems on body element */
  line-height: 30px;
  color: #0a0a0a;
  word-break: keep-all; }

/* Text select */
::selection {
  background: #0a0a0a;
  color: #ffffff; }

::-moz-selection {
  background: #0a0a0a;
  color: #ffffff; }

h1, h2, h3, h4, h5, h6 {
  color: #0a0a0a;
  margin-top: 0;
  margin-bottom: 2.3rem;
  font-weight: normal;
  letter-spacing: -0.05em; }

figure {
  margin: 0px; }

b, strong {
  font-weight: bold; }

em {
  font-style: italic !important; }

p {
  margin-bottom: 30px;
  color: #0a0a0a; }

/*.large-header-size{
    @include fp(font-size,30,60);
    line-height: 1.1em;
    letter-spacing: -0.05em;
}*/
.header-size, .header-1, h1, .header-2, h2, .header-3, h3, h4, h5, h6 {
  line-height: 1em;
  letter-spacing: 0em;
  font-size: calc(0.88496vw + 35.13274px);
  font-size: calc(1.45349vw + 25.5814px); }
  @media (max-width: 550px) {
    .header-size, .header-1, h1, .header-2, h2, .header-3, h3, h4, h5, h6 {
      font-size: 40px; } }
  @media (min-width: 5120px) {
    .header-size, .header-1, h1, .header-2, h2, .header-3, h3, h4, h5, h6 {
      font-size: 100px; } }

.copy-size, .header-3, h3, h4, h5, h6, body, .galleryLinks, .workGalleryNavHolder {
  line-height: 1.2em;
  letter-spacing: 0.021em;
  font-size: calc(0.44248vw + 17.56637px);
  font-size: calc(0.72674vw + 12.7907px); }
  @media (max-width: 550px) {
    .copy-size, .header-3, h3, h4, h5, h6, body, .galleryLinks, .workGalleryNavHolder {
      font-size: 20px; } }
  @media (min-width: 5120px) {
    .copy-size, .header-3, h3, h4, h5, h6, body, .galleryLinks, .workGalleryNavHolder {
      font-size: 50px; } }

.half-copy-size, .workCredit {
  line-height: 1.2em;
  letter-spacing: 0.021em;
  font-size: calc(0.22124vw + 8.78319px);
  font-size: calc(0.36337vw + 6.39535px); }
  @media (max-width: 550px) {
    .half-copy-size, .workCredit {
      font-size: 10px; } }
  @media (min-width: 5120px) {
    .half-copy-size, .workCredit {
      font-size: 25px; } }

.double-copy-size, .workCategoryNavHolder .workCategoryNav {
  line-height: 1.2em;
  letter-spacing: 0.021em;
  font-size: calc(0.88496vw + 35.13274px);
  font-size: calc(1.45349vw + 25.5814px); }
  @media (max-width: 550px) {
    .double-copy-size, .workCategoryNavHolder .workCategoryNav {
      font-size: 40px; } }
  @media (min-width: 5120px) {
    .double-copy-size, .workCategoryNavHolder .workCategoryNav {
      font-size: 100px; } }

.worklist-size, .page-workList .workListHolder {
  line-height: 1em;
  letter-spacing: 0.040em;
  font-size: calc(6.90265vw + -19.9646px);
  font-size: calc(5.93023vw + -3.62791px); }
  @media (max-width: 550px) {
    .worklist-size, .page-workList .workListHolder {
      font-size: 18px; } }
  @media (min-width: 5120px) {
    .worklist-size, .page-workList .workListHolder {
      font-size: 300px; } }

.workgrid-letter, .page-work .workGrid .workGridBlock .workGridBlock-item.letterBlock {
  /*line-height: 0.75em;
    letter-spacing: 0px;
    //550px 300px - 1680px 75px
    @include fp(font-size, 300, 400, 550, 1680, true, true, false);
    //1680px 75px - 5120px 96px
    @include fp(font-size, 400, 700, 1680, 5120, true, false, true);*/ }

/* Vendor */
/* Structure */
/* ==========================================================================
 Structure
 ========================================================================== */
body {
  background-color: #ffffff;
  transition: background-color 0.4s linear; }

.no-scroll {
  overflow-y: scroll; }

.no-scroll body {
  overflow: hidden !important; }

figure {
  margin-bottom: 17px; }

.image-link, a img {
  border: none !important;
  text-decoration: none !important; }

.main img {
  max-width: 100%; }

/* ==========================================================================
 Layout and styling
 ========================================================================== */
.page-loaded .main,
.page-loaded .footer {
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: auto; }

.pjax-loading .main,
.pjax-loading .footer {
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: none; }

.pageContent {
  min-height: 80vh;
  padding-top: 68px;
  max-width: 1000px;
  margin: auto; }

.page-homepage .pageContent,
.page-workEntry .pageContent {
  padding-top: 0px; }

.page-work .pageContent {
  padding-top: 23px; }

.touch .main {
  overflow-x: hidden; }

.footer-container {
  position: relative;
  display: block;
  clear: both;
  width: 100%;
  padding-bottom: 68px; }
  .footer-container .centreLock-margin {
    border-top: 6px solid #0a0a0a;
    padding-top: 24px; }
  .footer-container .socialLinks ul {
    list-style: none;
    margin: 0px;
    padding: 0px; }
    .footer-container .socialLinks ul li {
      margin: 0px;
      padding: 0px; }

.page-about,
.page-workList {
  background-color: #000;
  color: #fff; }
  .page-about p, .page-about h1, .page-about h2, .page-about h3, .page-about h4, .page-about a,
  .page-workList p,
  .page-workList h1,
  .page-workList h2,
  .page-workList h3,
  .page-workList h4,
  .page-workList a {
    color: #fff; }
  .page-about .footer-container .centreLock-margin,
  .page-workList .footer-container .centreLock-margin {
    border-color: #ffffff; }

/* Pages */
/* ==========================================================================
 Mixins
 ========================================================================== */
/*--------------------------------
Z-Index Manager
Usage:
.site-header {
    z-index: z('site-header');
}
*/
/*--------------------------------
When using ::before and ::after you'll always need these three.
So we're saving two lines of code every time you use this.
Usage:
div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*--------------------------------
Creating triangles
*/
/*--------------------------------
Calculated REM sizes with PX fallback
Usage:
p {
  @include font-size(14px)
}
Output:
p {
  font-size: 14px; //Will be overridden if browser supports rem
  font-size: 0.8rem;
}
*/
/*--------------------------------
Cross-Browser opacity
Usage:
.faded-text {
  @include opacity(0.8);
}
*/
/*--------------------------------
Retina images
Usage:
.element {
  @include retina {
    background-image: url(../img/background@2x.png);
  }
}
*/
/*--------------------------------
Responsive ratio
Used for creating scalable elements that maintain the same ratio
Usage:
.element {
  @include responsive-ratio(400, 300);
}
*/
/*--------------------------------
Truncate copy to width
*/
/*--------------------------------
Fluid Property
http://www.adrenalinmedia.com.au/the-agency/insights/this-changes-everything-css-fluid-properties.aspx
HTML:
<h1 class="element">
  <span>Text to replace</span>
</h1>
example:
h1 {
  @include fp(font-size, 50, 100); // 50px at 320, 100px at 1280;
}
output:
h1 {
  font-size: calc(3.125vw + 40px); //This is the magic!
}
@media (max-width:320px){ //Clips the start to the min value
  font-size:50px;
}
@media (min-width:1920px){ //Clips the end to the max value
  font-size:100px;
}
*/
/* ==========================================================================
 Home
 ========================================================================== */
.fullbleedGallery {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  color: #000000; }
  .fullbleedGallery .fullbleedGallery-content {
    width: 100%;
    height: 100%;
    height: 100vh; }
    .fullbleedGallery .fullbleedGallery-content .gallerySlide {
      width: 100%;
      height: 100%;
      overflow: hidden; }
    .fullbleedGallery .fullbleedGallery-content .gallerySlide.scaleToFit img {
      width: 100%;
      height: auto;
      display: block; }
    .fullbleedGallery .fullbleedGallery-content .gallerySlide.scaleToFit.landscape img {
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
    .fullbleedGallery .fullbleedGallery-content .gallerySlide.scaleToFit.portrait img {
      height: 100%;
      width: auto;
      max-width: unset;
      position: relative;
      left: 50%;
      transform: translateX(-50%); }
    .fullbleedGallery .fullbleedGallery-content .gallerySlide .desktopImage {
      display: block; }
    .fullbleedGallery .fullbleedGallery-content .gallerySlide.hasMobileImage .desktopImage {
      display: none; }
      @media (min-width: 751px) {
        .fullbleedGallery .fullbleedGallery-content .gallerySlide.hasMobileImage .desktopImage {
          display: block; } }
    .fullbleedGallery .fullbleedGallery-content .gallerySlide.hasMobileImage .mobileImage {
      display: block; }
      @media (min-width: 751px) {
        .fullbleedGallery .fullbleedGallery-content .gallerySlide.hasMobileImage .mobileImage {
          display: none; } }

.vp-short .fullbleedGallery .fullbleedGallery-content .gallerySlide.hasMobileImage .desktopImage {
  display: block; }

.vp-short .fullbleedGallery .fullbleedGallery-content .gallerySlide.hasMobileImage .mobileImage {
  display: none; }

.galleryCounter {
  float: left;
  display: inline-block;
  pointer-events: none;
  /*@include for-size(below-750){
        font-size: 25px;
        line-height: 30px;
        left: auto;
        right: $very-reduced-gutter-padding;
        transform: translate(0%,0%);
    }*/ }
  .galleryCounter .galCountSeparator {
    margin-left: 3px;
    margin-right: 3px; }

.galleryLinks {
  pointer-events: none;
  position: absolute;
  bottom: 36px;
  padding-left: 18px;
  padding-right: 18px;
  transition: padding 0.4s ease;
  will-change: padding;
  width: 100%;
  box-sizing: border-box; }
  .galleryLinks .galleryLinks-content {
    /*.gallerySlideLink:hover span{
            transform: rotate( - $hoverSkewDegrees) translate3d(0,- $hoverSkewOffset / 2,0);
        }*/ }
    .galleryLinks .galleryLinks-content .gallerySlideTitle {
      position: absolute;
      display: block;
      pointer-events: none;
      top: 0px;
      left: 90px; }
    .galleryLinks .galleryLinks-content .gallerySlideLink {
      color: #ffffff;
      text-decoration: none;
      border: none;
      display: block;
      pointer-events: none;
      font-style: normal;
      opacity: 0;
      filter: alpha(opacity=0);
      /*span{
                float: left;
                transition: transform 0.3s ease;
            }*/ }
    .galleryLinks .galleryLinks-content .gallerySlideLink.shown {
      pointer-events: auto;
      opacity: 1;
      filter: alpha(opacity=100); }
    .galleryLinks .galleryLinks-content .gallerySlideLink:hover {
      font-style: normal; }
  @media (min-width: 415px) {
    .galleryLinks .galleryLinks-content .gallerySlideTitle {
      display: inline-block; }
    .galleryLinks .galleryLinks-content .gallerySlideLink {
      display: inline-block; } }
  @media (min-width: 751px) {
    .galleryLinks .galleryLinks-content .gallerySlideTitle {
      display: block;
      float: left; }
    .galleryLinks .galleryLinks-content .gallerySlideLink {
      display: block;
      float: right; } }

@media (min-width: 750px) {
  .galleryLinks {
    padding-left: 24px;
    padding-right: 24px; }
    .galleryLinks .galleryLinks-content .gallerySlideTitle {
      left: 96px; } }

@media (min-width: 767px) {
  .galleryLinks {
    padding-left: 36px;
    padding-right: 36px; }
    .galleryLinks .galleryLinks-content .gallerySlideTitle {
      left: 108px; } }

@media (min-width: 2800px) {
  .galleryLinks {
    padding-left: 48px;
    padding-right: 48px; }
    .galleryLinks .galleryLinks-content .gallerySlideTitle {
      left: 120px; } }

/* ==========================================================================
 About
 ========================================================================== */
.page-about img {
  transition: opacity 0.35s; }

.page-about img.lazyload,
.page-about img.lazyloading {
  opacity: 0.001;
  filter: alpha(opacity=0.1); }

.page-about img.lazyloaded {
  opacity: 1;
  filter: alpha(opacity=100); }

.aboutContentBlocks .contentBlock-copy .contentBlockContent, .aboutContentBlocks .contentBlock-list .contentBlockContent {
  position: relative;
  width: 100%; }
  .aboutContentBlocks .contentBlock-copy .contentBlockContent .contentBlock-copy-title, .aboutContentBlocks .contentBlock-copy .contentBlockContent .contentBlock-list-title,
  .aboutContentBlocks .contentBlock-copy .contentBlockContent .contentBlock-copy-text, .aboutContentBlocks .contentBlock-copy .contentBlockContent .contentBlock-list-text, .aboutContentBlocks .contentBlock-list .contentBlockContent .contentBlock-copy-title, .aboutContentBlocks .contentBlock-list .contentBlockContent .contentBlock-list-title,
  .aboutContentBlocks .contentBlock-list .contentBlockContent .contentBlock-copy-text, .aboutContentBlocks .contentBlock-list .contentBlockContent .contentBlock-list-text {
    display: inline-block;
    float: left;
    width: 100%; }
    @media (min-width: 769px) {
      .aboutContentBlocks .contentBlock-copy .contentBlockContent .contentBlock-copy-title, .aboutContentBlocks .contentBlock-copy .contentBlockContent .contentBlock-list-title,
      .aboutContentBlocks .contentBlock-copy .contentBlockContent .contentBlock-copy-text, .aboutContentBlocks .contentBlock-copy .contentBlockContent .contentBlock-list-text, .aboutContentBlocks .contentBlock-list .contentBlockContent .contentBlock-copy-title, .aboutContentBlocks .contentBlock-list .contentBlockContent .contentBlock-list-title,
      .aboutContentBlocks .contentBlock-list .contentBlockContent .contentBlock-copy-text, .aboutContentBlocks .contentBlock-list .contentBlockContent .contentBlock-list-text {
        width: 50%;
        width: calc(50% - 10px);
        margin-right: 20px; } }
  .aboutContentBlocks .contentBlock-copy .contentBlockContent .contentBlock-copy-title, .aboutContentBlocks .contentBlock-copy .contentBlockContent .contentBlock-list-title, .aboutContentBlocks .contentBlock-list .contentBlockContent .contentBlock-copy-title, .aboutContentBlocks .contentBlock-list .contentBlockContent .contentBlock-list-title {
    padding-top: 0px; }
  .aboutContentBlocks .contentBlock-copy .contentBlockContent .contentBlock-copy-text, .aboutContentBlocks .contentBlock-copy .contentBlockContent .contentBlock-list-text, .aboutContentBlocks .contentBlock-list .contentBlockContent .contentBlock-copy-text, .aboutContentBlocks .contentBlock-list .contentBlockContent .contentBlock-list-text {
    margin-right: 0px !important; }

.aboutContentBlocks {
  width: 100%;
  clear: both; }
  .aboutContentBlocks .contentBlock {
    padding-top: 0px;
    padding-bottom: 0px; }
    .aboutContentBlocks .contentBlock .contentBlockContent {
      border-top: 6px solid #0a0a0a;
      padding-top: 24px; }
  .aboutContentBlocks .contentBlock-copy {
    padding-bottom: 36px; }
  .aboutContentBlocks .contentBlock-list {
    padding-bottom: 66px; }
    .aboutContentBlocks .contentBlock-list ul {
      list-style: none;
      margin: 0px;
      padding: 0px; }
      .aboutContentBlocks .contentBlock-list ul li {
        margin: 0px;
        padding: 0px; }
  .aboutContentBlocks .contentBlock-image .contentBlockContent {
    border: none;
    padding-top: 0px !important; }

.aboutGalleries {
  width: 100%;
  clear: both; }

.aboutGallery .multiImageGallery img, .aboutGallery .singleImageGallery img {
  width: 100%; }

@media (max-width: 750px) {
  .aboutGallery .multiImageGallery, .aboutGallery .singleImageGallery {
    padding-left: 0px;
    padding-right: 0px; } }

.aboutGalleries-title {
  border-top: 6px solid #0a0a0a;
  padding-top: 23px; }
  .aboutGalleries-title h2 {
    margin-bottom: 30px; }

.aboutGallery-copy {
  margin-top: 17px;
  margin-bottom: 66px; }
  .aboutGallery-copy p {
    margin-bottom: 0px; }
  .aboutGallery-copy .subtitle {
    font-style: italic; }

.headshotGallery {
  width: 100%;
  clear: both; }
  @media (max-width: 750px) {
    .headshotGallery .headshots {
      padding-left: 0px;
      padding-right: 0px; } }
  .headshotGallery .headshots .teamMember {
    width: 100%;
    display: inline-block;
    float: left; }
    @media (min-width: 751px) {
      .headshotGallery .headshots .teamMember {
        width: 50%;
        width: calc(50% - 12px);
        margin-right: 24px; } }
    @media (min-width: 1681px) {
      .headshotGallery .headshots .teamMember {
        width: 33%;
        width: calc(33.333% - 16px);
        margin-right: 24px; } }
    @media (min-width: 5121px) {
      .headshotGallery .headshots .teamMember {
        width: 16%;
        width: calc(16.666% - 20px);
        margin-right: 24px; } }
    .headshotGallery .headshots .teamMember .teamMember-image img {
      width: 100%; }
    .headshotGallery .headshots .teamMember .teamMember-copy {
      padding-top: 18px;
      padding-bottom: 66px; }
      .headshotGallery .headshots .teamMember .teamMember-copy p {
        margin: 0px; }
      @media (min-width: 751px) {
        .headshotGallery .headshots .teamMember .teamMember-copy {
          padding-left: 0px;
          padding-right: 0px; } }
  @media (min-width: 751px) and (max-width: 1680px) {
    .headshotGallery .headshots .teamMember:nth-child(even) {
      margin-right: 0px; } }
  @media (min-width: 1681px) and (max-width: 5120px) {
    .headshotGallery .headshots .teamMember {
      margin-right: 24px; }
    .headshotGallery .headshots .teamMember:nth-child(3n) {
      margin-right: 0px; } }
  @media (min-width: 5121px) {
    .headshotGallery .headshots .teamMember {
      margin-right: 24px; }
    .headshotGallery .headshots .teamMember:nth-child(6n) {
      margin-right: 0px; } }

.teamRole {
  font-style: italic; }

.page-about .aboutContentBlocks .contentBlock .contentBlockContent,
.page-about .aboutGalleries .aboutGalleries-title,
.page-about .siteCredit .siteCreditContent,
.page-about .footer-container .centreLock-margin {
  border-color: #fff; }

.page-about .siteCredit .siteCreditContent {
  color: #636466; }
  .page-about .siteCredit .siteCreditContent a {
    color: #636466; }

/* ==========================================================================
 Contact
 ========================================================================== */
.page-contact img {
  transition: opacity 0.35s; }

.page-contact img.lazyload,
.page-contact img.lazyloading {
  opacity: 0.001;
  filter: alpha(opacity=0.1); }

.page-contact img.lazyloaded {
  opacity: 1;
  filter: alpha(opacity=100); }

.officeImages, .officeImage {
  width: 100%;
  margin-bottom: 18px; }
  @media (max-width: 750px) {
    .officeImages, .officeImage {
      padding-left: 0px;
      padding-right: 0px; } }

@media (min-width: 2801px) {
  .officeImage {
    padding: 0px; } }

.officeImage img {
  width: 100%; }

@media (min-width: 2801px) {
  .offices .offices-content {
    padding-left: 48px;
    padding-right: 48px; } }

.offices .offices-content .office {
  width: 100%;
  display: inline-block;
  float: left; }
  @media (min-width: 2801px) {
    .offices .offices-content .office {
      width: 50%;
      width: calc(50% - 10px);
      margin-right: 20px; }
      .offices .offices-content .office .officeImages {
        padding: 0px; } }
  .offices .offices-content .office .office-details {
    margin-bottom: 36px; }
    @media (min-width: 2801px) {
      .offices .offices-content .office .office-details {
        padding: 0px; } }
    .offices .offices-content .office .office-details .office-title, .offices .offices-content .office .office-details .office-copy {
      width: 100%;
      display: inline-block;
      float: left; }
      @media (min-width: 751px) {
        .offices .offices-content .office .office-details .office-title, .offices .offices-content .office .office-details .office-copy {
          width: 50%;
          width: calc(50% - 10px);
          margin-right: 20px; } }
    .offices .offices-content .office .office-details .office-title {
      margin-top: 0px; }
    .offices .offices-content .office .office-details .office-copy {
      margin-right: 0px !important;
      padding-top: 2px; }

.offices .offices-content .office:nth-child(even) {
  margin-right: 0px !important; }

.contactLinks {
  width: 100%;
  clear: both;
  margin-bottom: 36px;
  color: #c7c8ca; }
  .contactLinks a, .contactLinks p {
    color: #c7c8ca; }
  .contactLinks .contactLinks-content {
    border-top: 6px solid #0a0a0a;
    padding-top: 23px; }
    .contactLinks .contactLinks-content .contactLinks-block {
      width: 100%;
      display: inline-block;
      float: left; }
      @media (min-width: 751px) {
        .contactLinks .contactLinks-content .contactLinks-block {
          width: 50%;
          width: calc(50% - 10px);
          margin-right: 20px; } }
    .contactLinks .contactLinks-content .contactNotes {
      margin-right: 0px !important; }

.page-contact .aboutContentBlocks {
  display: none; }

/* ==========================================================================
 Work
 ========================================================================== */
.page-workEntry img {
  transition: opacity 0.35s; }

.page-workEntry img.lazyload,
.page-workEntry img.lazyloading {
  opacity: 0.001;
  filter: alpha(opacity=0.1); }

.page-workEntry img.lazyloaded {
  opacity: 1;
  filter: alpha(opacity=100); }

.photoCredit {
  font-size: 10px;
  line-height: 1.2em;
  text-transform: uppercase;
  text-align: center;
  color: #c0c0c0; }

.workCredit, .workScrollLinkolder {
  width: 100%;
  display: inline-block;
  float: left; }
  @media (min-width: 701px) {
    .workCredit, .workScrollLinkolder {
      width: 50%; } }

.workCredit {
  text-transform: uppercase; }
  .workCredit p {
    color: #c0c0c0 !important;
    line-height: 3.3em; }

@media (min-width: 701px) {
  .workScrollLinkHolder {
    text-align: right; } }

.workScrollLinkHolder .workScrollLink {
  font-style: normal; }

.workScrollLinkHolder .workScrollLink:hover {
  font-style: italic; }

.page-work .footer-container {
  padding-bottom: 10px !important; }
  .page-work .footer-container .centreLock-margin {
    border: none !important;
    padding-top: 14px; }

.page-work .workGrid {
  width: 100%;
  overflow-x: hidden;
  position: relative;
  margin-top: 134px;
  padding-bottom: 0px; }
  .page-work .workGrid .workGridBlock {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    width: calc(100% + 36px);
    /*.workGridBlock-item.letterBlock{
                @include for-size(below-700){
                    width: 84% !important;
                    svg .wgLetter .letterText{
                        //x: 50% !important;
                        //text-anchor: middle !important;
                    }
                }
            }*/ }
    @media (max-width: 768px) {
      .page-work .workGrid .workGridBlock {
        padding-top: 28px; } }
    .page-work .workGrid .workGridBlock .workGridBlock-item {
      flex-grow: 0;
      width: 50%;
      box-sizing: border-box;
      float: left;
      padding-bottom: 36px;
      padding-right: 36px; }
      @media (max-width: 768px) {
        .page-work .workGrid .workGridBlock .workGridBlock-item {
          width: 100% !important; } }
      .page-work .workGrid .workGridBlock .workGridBlock-item .workGridBlock-contents .workGridBlock-link {
        display: block;
        position: relative;
        font-style: normal; }
        .page-work .workGrid .workGridBlock .workGridBlock-item .workGridBlock-contents .workGridBlock-link .overageOverlay {
          display: none;
          position: absolute;
          bottom: 10px;
          right: 15px;
          color: #ffffff;
          opacity: 0.75;
          filter: alpha(opacity=75); }
        @media (min-width: 769px) {
          .page-work .workGrid .workGridBlock .workGridBlock-item .workGridBlock-contents .workGridBlock-link .mobileOverageOverlay {
            display: none; } }
      .page-work .workGrid .workGridBlock .workGridBlock-item .workGridBlock-contents img {
        display: block;
        width: 100%; }
    @media (max-width: 768px) {
      .page-work .workGrid .workGridBlock .workGridBlock-item-other {
        display: none; } }
    .page-work .workGrid .workGridBlock .workGridBlock-item:last-child {
      flex-grow: 0; }
    @media (max-width: 768px) {
      .page-work .workGrid .workGridBlock .workGridBlock-item.letterBlock {
        padding-top: 25px;
        padding-bottom: 62px; } }
    .page-work .workGrid .workGridBlock .workGridBlock-item.reducedWidth,
    .page-work .workGrid .workGridBlock .workGridBlock-item.letterBlock {
      width: 33.3%; }
      @media (max-width: 1024px) {
        .page-work .workGrid .workGridBlock .workGridBlock-item.reducedWidth,
        .page-work .workGrid .workGridBlock .workGridBlock-item.letterBlock {
          width: 49.9%; } }
      @media (max-width: 768px) {
        .page-work .workGrid .workGridBlock .workGridBlock-item.reducedWidth,
        .page-work .workGrid .workGridBlock .workGridBlock-item.letterBlock {
          width: 100% !important; } }
    @media (min-width: 769px) {
      .page-work .workGrid .workGridBlock .workGridBlock-item.letterBlock {
        width: auto;
        height: 40vh; } }
    .page-work .workGrid .workGridBlock .workGridBlock-item.letterBlock .workGridBlock-contents {
      height: 100%;
      width: 100%;
      /*svg{
                        pointer-events: none;
                        overflow: visible !important;
                    }*/ }
      .page-work .workGrid .workGridBlock .workGridBlock-item.letterBlock .workGridBlock-contents .workGridBlock-letterChar {
        height: 100%;
        width: 100%; }
        .page-work .workGrid .workGridBlock .workGridBlock-item.letterBlock .workGridBlock-contents .workGridBlock-letterChar .desktopLetter {
          width: auto;
          height: 100%;
          max-width: unset;
          display: none; }
          @media (min-width: 769px) {
            .page-work .workGrid .workGridBlock .workGridBlock-item.letterBlock .workGridBlock-contents .workGridBlock-letterChar .desktopLetter {
              display: block; } }
        .page-work .workGrid .workGridBlock .workGridBlock-item.letterBlock .workGridBlock-contents .workGridBlock-letterChar .mobileLetter {
          width: 75%;
          margin: auto;
          display: block; }
          @media (min-width: 769px) {
            .page-work .workGrid .workGridBlock .workGridBlock-item.letterBlock .workGridBlock-contents .workGridBlock-letterChar .mobileLetter {
              display: none; } }
          @media (max-width: 550px) {
            .page-work .workGrid .workGridBlock .workGridBlock-item.letterBlock .workGridBlock-contents .workGridBlock-letterChar .mobileLetter {
              width: 100%; } }
    .page-work .workGrid .workGridBlock .workGridBlock-item.extraPadding-left {
      padding-left: 36px; }
    .page-work .workGrid .workGridBlock .workGridBlock-item.extraPadding-right {
      padding-right: 72px; }
    .page-work .workGrid .workGridBlock .workGridBlock-item.finalExtraPadding-left {
      padding-left: 72px; }
    .page-work .workGrid .workGridBlock .workGridBlock-item.finalExtraPadding-right {
      padding-right: 108px; }
    @media (max-width: 1024px) {
      .page-work .workGrid .workGridBlock .workGridBlock-item.finalExtraPadding-left {
        padding-left: 0px; }
      .page-work .workGrid .workGridBlock .workGridBlock-item.finalExtraPadding-right {
        padding-right: 36px; }
      .page-work .workGrid .workGridBlock .workGridBlock-item.extraPadding-left {
        padding-left: 0px; }
      .page-work .workGrid .workGridBlock .workGridBlock-item.extraPadding-right {
        padding-right: 36px; } }
    .page-work .workGrid .workGridBlock .workGridBlock-item.xsmall {
      width: 23.9%; }
    .page-work .workGrid .workGridBlock .workGridBlock-item.small {
      width: 35.9%; }
    .page-work .workGrid .workGridBlock .workGridBlock-item.medium {
      width: 47.9%; }
    .page-work .workGrid .workGridBlock .workGridBlock-item.large {
      width: 57.9%; }
      @media (max-width: 1024px) {
        .page-work .workGrid .workGridBlock .workGridBlock-item.large {
          width: 47.9%; } }
    .page-work .workGrid .workGridBlock .workGridBlock-item.xlarge {
      width: 74.9%; }
    .page-work .workGrid .workGridBlock .workGridBlock-item.feature {
      width: 100%; }
      .page-work .workGrid .workGridBlock .workGridBlock-item.feature .workGridBlock-contents {
        width: 80%;
        margin: auto; }

/*@include for-size($workGridAboveBreakpoint){
    .workGridBlock-letter-A{
        img{
            margin-left:11%;
        }
    }
    .workGridBlock-letter-B{
        img{
            margin-left:18%;
        }
    }
    .workGridBlock-letter-C{
        img{
            margin-left:12%;
        }
    }
    .workGridBlock-letter-D{
        img{
            margin-left:14%;
        }
    }
    .workGridBlock-letter-E{
        img{
            margin-left:20%;
        }
    }
    .workGridBlock-letter-F{
        img{
            margin-left:22%;
        }
    }
    .workGridBlock-letter-G{
        img{
            margin-left:11%;
        }
    }
    .workGridBlock-letter-H{
        img{
            margin-left:15%;
        }
    }
    .workGridBlock-letter-I{
        img{
            margin-left:45%;
        }
    }
    .workGridBlock-letter-J{
        img{
            margin-left:21%;
        }
    }
    .workGridBlock-letter-K{
        img{
            margin-left:17%;
        }
    }
    .workGridBlock-letter-L{
        img{
            margin-left:22%;
        }
    }
    .workGridBlock-letter-M{
        img{
            margin-left:10%;
        }
    }
    .workGridBlock-letter-N{
        img{
            margin-left:17%;
        }
    }
    .workGridBlock-letter-O{
        img{
            margin-left:10%;
        }
    }
    .workGridBlock-letter-P{
        img{
            margin-left:21%;
        }
    }
    .workGridBlock-letter-Q{
        img{
            margin-left:10%;
        }
    }
    .workGridBlock-letter-R{
        img{
            margin-left:20%;
        }
    }
    .workGridBlock-letter-S{
        img{
            margin-left:18%;
        }
    }
    .workGridBlock-letter-T{
        img{
            margin-left:14%;
        }
    }
    .workGridBlock-letter-U{
        img{
            margin-left:18%;
        }
    }
    .workGridBlock-letter-V{
        img{
            margin-left:13%;
        }
    }
    .workGridBlock-letter-X{
        img{
            margin-left:14%;
        }
    }
    .workGridBlock-letter-Y{
        img{
            margin-left:15%;
        }
    }
    .workGridBlock-letter-Z{
        img{
            margin-left:18%;
        }
    }
}*/
@media (max-width: 768px) {
  .workGridBlock-letterChar img {
    margin-top: 0px !important; } }

.no-touch .page-work .workGrid .workGridBlock-link {
  transition: opacity 0.2s linear; }

.no-touch .page-work .workGrid.hovering .workGridBlock-link {
  opacity: 0.7;
  filter: alpha(opacity=70); }

.no-touch .page-work .workGrid.hovering .workGridBlock-link.hovering {
  opacity: 1;
  filter: alpha(opacity=100);
  transition: opacity 0.5s ease; }

.workCategoryNavHolder {
  width: 100%; }
  .workCategoryNavHolder .workCategoryNav {
    list-style: none;
    margin: 0px;
    padding: 0px; }
    .workCategoryNavHolder .workCategoryNav li {
      margin: 0px;
      padding: 0px; }
      .workCategoryNavHolder .workCategoryNav li a {
        font-style: normal;
        display: inline-block; }
      .workCategoryNavHolder .workCategoryNav li a:hover, .workCategoryNavHolder .workCategoryNav li a.selected {
        font-style: italic; }
      .workCategoryNavHolder .workCategoryNav li a.selected:hover {
        font-style: normal; }
    .workCategoryNavHolder .workCategoryNav li {
      display: inline-block;
      float: left; }

@media (min-width: 701px) {
  .no-touch .workCategoryNavHolder {
    z-index: 2; }
    .no-touch .workCategoryNavHolder .workCategoryNav a {
      transition: opacity 0.3s ease; }
      .no-touch .workCategoryNavHolder .workCategoryNav a span.comma {
        transition: opacity 0.3s ease; }
    .no-touch .workCategoryNavHolder .workCategoryNav .navcopy {
      transition: opacity 0.3s ease; }
    .no-touch .workCategoryNavHolder .workCategoryNav.gridmatch a {
      opacity: 0;
      filter: alpha(opacity=0); }
      .no-touch .workCategoryNavHolder .workCategoryNav.gridmatch a span.comma {
        opacity: 0;
        filter: alpha(opacity=0); }
      .no-touch .workCategoryNavHolder .workCategoryNav.gridmatch a.gridmatch-select {
        opacity: 1 !important;
        filter: alpha(opacity=100) !important; }
    .no-touch .workCategoryNavHolder .workCategoryNav.gridmatch .navcopy {
      opacity: 0;
      filter: alpha(opacity=0); } }

.workGalleryOverlayShown .workCategoryNavHolder {
  display: none; }

.workGalleryOverlay {
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
  position: fixed;
  width: 100%;
  top: 0px;
  background: #ffffff;
  z-index: 2;
  padding-top: 50px;
  transition: opacity 0.3s linear; }
  @media (min-width: 701px) {
    .workGalleryOverlay {
      padding-top: 50px !important;
      min-height: unset; } }
  .workGalleryOverlay .letterSlide {
    text-align: center;
    /*svg{
            margin: auto;
            width: auto;
            height: 100%;
            overflow: visible;
        }*/ }
  .workGalleryOverlay .closeWorkOverlay {
    position: absolute;
    top: 0px;
    right: 18px; }
    .workGalleryOverlay .closeWorkOverlay a {
      font-style: normal;
      color: #000000 !important; }
    .workGalleryOverlay .closeWorkOverlay a:hover {
      font-style: italic; }

@media (min-width: 750px) {
  .workGalleryOverlay .closeWorkOverlay {
    right: 24px; } }

@media (min-width: 767px) {
  .workGalleryOverlay .closeWorkOverlay {
    right: 36px; } }

@media (min-width: 2800px) {
  .workGalleryOverlay .closeWorkOverlay {
    right: 48px; } }

.workGalleryOverlayShown .workGalleryOverlay {
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: auto; }
  .workGalleryOverlayShown .workGalleryOverlay .closeWorkOverlay {
    display: block; }

.workGalleryOverlayShown .page-workList {
  color: #0a0a0a; }
  .workGalleryOverlayShown .page-workList .workPreviewHolder {
    display: none !important; }

.workGalleryHolder {
  margin-top: 35px;
  margin-bottom: 24px; }
  @media (max-width: 750px) {
    .workGalleryHolder {
      margin-top: 24px;
      padding-left: 0px !important;
      padding-right: 0px !important; } }
  .workGalleryHolder .workGallery {
    width: 100%;
    min-height: 80vh;
    min-height: calc(100vh - 58px - 52px - 59px - 2px); }
    .workGalleryHolder .workGallery .flickity-viewport {
      width: 100%;
      height: 100%;
      position: absolute; }
    .workGalleryHolder .workGallery .workGallerySlide {
      width: 100%;
      height: 100%; }
      .workGalleryHolder .workGallery .workGallerySlide img {
        box-sizing: border-box; }
      .workGalleryHolder .workGallery .workGallerySlide .photoCredit {
        position: absolute;
        right: -45%;
        top: 50%;
        right: calc(-50% + 30px);
        width: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        transition: opacity 0.3s linear; }
        @media (min-width: 751px) {
          .workGalleryHolder .workGallery .workGallerySlide .photoCredit {
            right: calc(-50% + 42px); } }
    .workGalleryHolder .workGallery .workGallerySlide.is-selected .photoCredit {
      opacity: 1;
      filter: alpha(opacity=100); }
    .workGalleryHolder .workGallery .workGallerySlide.hasCredit img {
      padding-left: 40px;
      padding-right: 40px; }

.workGalleryNavHolder {
  pointer-events: none;
  padding-bottom: 32px;
  width: 100%; }
  @media (min-width: 701px) {
    .workGalleryNavHolder {
      position: absolute;
      left: 0px;
      bottom: 50px;
      bottom: calc(36px + 0.6em); } }
  .workGalleryNavHolder .workGalleryNav .workGalleryCounter {
    min-width: 48px; }
    @media (min-width: 751px) {
      .workGalleryNavHolder .workGalleryNav .workGalleryCounter {
        min-width: 72px; } }
  .workGalleryNavHolder .workGalleryNav .workGalleryCounter, .workGalleryNavHolder .workGalleryNav .workGalleryTitle {
    display: inline-block; }

.vp-short .workGalleryHolder .photoCredit {
  display: none; }

.vp-short .workGalleryHolder .workGallerySlide.hasCredit img {
  padding-left: 40px;
  padding-right: 40px; }

.vp-short .workGalleryHolder .workGallery {
  min-height: 70vh; }

.page-workList .workListHolder {
  padding-bottom: 70px;
  text-transform: uppercase;
  min-height: 70vh; }
  .page-workList .workListHolder .workList {
    list-style: none;
    margin: 0px;
    padding: 0px; }
    .page-workList .workListHolder .workList .workList-el {
      margin: 0px;
      padding: 0px; }
      .page-workList .workListHolder .workList .workList-el .workList-link {
        font-style: normal;
        display: inline-block;
        clear: both; }
      .page-workList .workListHolder .workList .workList-el .workList-link:hover {
        font-style: italic; }

.page-workList .workPreviewHolder {
  position: fixed;
  top: 10%;
  left: 19%;
  width: 62%;
  height: 80%;
  pointer-events: none;
  z-index: 4; }
  .page-workList .workPreviewHolder .workPreview {
    pointer-events: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: none; }
  .page-workList .workPreviewHolder .workPreview.shown {
    display: block; }

.flickity-button {
  width: 50%;
  border-radius: 0px;
  height: 100%;
  background: transparent !important;
  opacity: 0;
  filter: alpha(opacity=0); }
  .flickity-button svg {
    display: none; }

.touch .flickity-button {
  display: none !important; }

.no-touch .flickity-button.flickity-prev-next-button.next {
  cursor: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGNsYXNzPSJyaWdodEFycm93LXN2ZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNS4xIiBoZWlnaHQ9IjIwIiB2aWV3Qm94PSIwIDAgMjUuMSAyMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjUuMSAyMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGQ9Ik0wLDguOGgyMC43TDExLjgsMGgzLjNsMTAsMTBsLTEwLDEwaC0zLjNsOS04LjlIMFY4Ljh6Ii8+PC9zdmc+Cg==), auto; }
  @media (min-width: 1681px) {
    .no-touch .flickity-button.flickity-prev-next-button.next {
      cursor: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGNsYXNzPSJyaWdodEFycm93LXN2ZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIzMS4zIiBoZWlnaHQ9IjI1IiB2aWV3Qm94PSIwIDAgMzEuMyAyNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzEuMyAyNTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGQ9Ik0wLDExaDI1LjlMMTQuNywwaDQuMWwxMi41LDEyLjVMMTguOCwyNWgtNC4xTDI2LDEzLjlIMFYxMXoiLz48L3N2Zz4K), auto; } }
  @media (min-width: 2801px) {
    .no-touch .flickity-button.flickity-prev-next-button.next {
      cursor: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGNsYXNzPSJyaWdodEFycm93LXN2ZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIzNy41IiBoZWlnaHQ9IjMwIiB2aWV3Qm94PSIwIDAgMzcuNSAzMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzcuNSAzMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGQ9Ik0tMC44LDEzLjJoMzEuMUwxNi45LDBoNC45bDE1LDE1bC0xNSwxNWgtNC45bDEzLjUtMTMuM0gtMC44VjEzLjJ6Ii8+PC9zdmc+Cg==), auto; } }

.no-touch .flickity-button.flickity-prev-next-button.previous {
  cursor: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGNsYXNzPSJsZWZ0QXJyb3ctc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI1LjEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyNS4xIDIwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNS4xIDIwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTI1LjEsMTEuMkg0LjNsOSw4LjhIMTBMMCwxMEwxMCwwaDMuM2wtOSw4LjloMjAuOFYxMS4yeiIvPjwvc3ZnPgo=), auto; }
  @media (min-width: 1681px) {
    .no-touch .flickity-button.flickity-prev-next-button.previous {
      cursor: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGNsYXNzPSJsZWZ0QXJyb3ctc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMxLjMiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAzMS4zIDI1IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMS4zIDI1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTMxLjMsMTRoLTI2bDExLjIsMTFoLTQuMUwwLDEyLjVMMTIuNSwwaDQuMUw1LjQsMTEuMWgyNlYxNHoiLz48L3N2Zz4K), auto; } }
  @media (min-width: 2801px) {
    .no-touch .flickity-button.flickity-prev-next-button.previous {
      cursor: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGNsYXNzPSJsZWZ0QXJyb3ctc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjM3LjYiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzNy42IDMwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzNy42IDMwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTM3LjYsMTYuOEg2LjRMMTkuOSwzMEgxNUwwLDE1TDE1LDBoNC45TDYuNCwxMy4zaDMxLjJWMTYuOHoiLz48L3N2Zz4K), auto; } }

.workGalleryNav a {
  color: #0a0a0a !important; }

.workGallerySlideTitleLink {
  font-style: normal; }

.workGallerySlideTitleLink.linkActive {
  pointer-events: auto; }

.workGallerySlideTitleLink.linkActive:hover {
  font-style: italic; }

/* Elements */
/* ==========================================================================
 Forms
 ========================================================================== */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  border-radius: 0px !important;
  background-color: none;
  width: 100%;
  max-width: 400px; }

textarea {
  min-height: 150px; }

::placeholder {
  color: #ff0000;
  opacity: 1; }

::-webkit-resizer {
  display: none; }

input[type="submit"] {
  display: block;
  border-radius: 0px !important;
  background-color: none;
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0px;
  padding-left: 50px;
  padding-right: 50px; }

.login-form-holder {
  max-width: 400px;
  margin: auto; }

/* ==========================================================================
 Shared Elements
 ========================================================================== */
/* ==========================================================================
 Content Blocks
 ========================================================================== */
.contentBlock {
  padding-top: 15px;
  padding-bottom: 55px;
  position: relative; }
  .contentBlock .columns {
    position: relative; }
  .contentBlock img {
    width: 100%; }
